<template>
  <div>
    <div class="function">
      <div class="function-title">立体综合安防系统</div>
      <div class="security-text">
        <p>
          安防重点：开放性园区在室外形成网格化、无视角、无缝连接的监控（周界）。
        </p>
        <p>
          人脸识别：在园区出入口、核心受控区等地点形成更精细化的人员管理功能。
        </p>
      </div>
      <div class="security-box">
        <div
          class="security-item"
          v-for="(item, index) in anfList"
          :key="index"
        >
          <img :src="item.url" alt="" />
          <div>{{ item.name }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
 
<script>
export default {
  components: {},
  name: "",
  data() {
    return {
      anfList: [
        {
          url: require("../../../../assets/image/parkImg/anf1.png"),
          name: "无线对讲",
        },
        {
          url: require("../../../../assets/image/parkImg/anf2.png"),
          name: "可视对讲",
        },
        {
          url: require("../../../../assets/image/parkImg/anf3.png"),
          name: "防盗报警",
        },
        {
          url: require("../../../../assets/image/parkImg/anf4.png"),
          name: "电子巡查",
        },
        {
          url: require("../../../../assets/image/parkImg/anf5.png"),
          name: "高空瞭望自动跟踪",
        },
        {
          url: require("../../../../assets/image/parkImg/anf6.png"),
          name: "周界防护",
        },
        {
          url: require("../../../../assets/image/parkImg/anf7.png"),
          name: "机器人巡检",
        },
        {
          url: require("../../../../assets/image/parkImg/anf8.png"),
          name: "人脸识别、图像识别",
        },
      ],
    };
  },

  methods: {},
};
</script>
 
<style scoped lang="scss">
.function {
  padding: 0 10%;
  margin-bottom: 50px;
  .function-title {
    // text-align: center;
    padding: 30px 0;
    font-size: 40px;
    font-weight: 600;
    color: #333333;
  }

  .scenes-box {
    .scenes-bg {
      width: 100%;
      height: 420px;
      background: url("../../../../assets/image/parkImg/scenes.png") no-repeat;
      background-size: 100%;
    }
    .scenes-text {
      width: 100%;
      height: 400px;
      text-align: center;
      background-color: #fff;
      .title {
        font-size: 28px;
        font-weight: 600;
        padding: 20px 0;
        color: #333333;
      }
      .text-box {
        display: flex;
        .text-box-item {
          width: 16%;
          font-size: 16px;
          color: #6b6b6b;
          margin-top: 60px;
          border-right: 1px solid #dfdfdf;
          p {
            padding: 10px 0;
          }
        }
      }
    }
  }
  .services {
    width: 100%;
    height: 500px;
    display: flex;
    background-color: #fff;
    .left {
      width: 65%;
      height: 500px;
      background: url("../../../../assets/image/parkImg/services.png") no-repeat;
      background-size: 100%;
    }
    .right {
      width: 30%;
      padding: 6%;
      box-sizing: border-box;
      div {
        font-size: 24px;
        color: #6b6b6b;
      }
    }
  }
  .nh-text {
    font-size: 28px;
    margin: 20px 0;
    color: #6b6b6b;
  }
  .nh-box {
    display: flex;
    text-align: center;
    justify-content: space-between;

    .nh-a {
      width: 32%;
      .nh-title {
        width: 100%;
        height: 60px;
        line-height: 60px;
        font-size: 24px;
        color: #3651d8;
        background-color: #d6ddff;
      }
      .nh-grid {
        display: flex;
        margin: 3% 0;
        .grid-left {
          width: 60%;
          height: 200px;
          background: url("../../../../assets/image/parkImg/nh1.png");
          padding: 5%;
          text-align: left;
          box-sizing: border-box;
          span {
            color: #fff;
            font-size: 20px;
          }
        }
        .grid-right {
          width: 40%;
          height: 200px;
          padding: 3%;
          display: flex;
          text-align: left;
          flex-direction: column;
          justify-content: space-between;
          box-sizing: border-box;
          background-color: #fff;
          .grid-line {
            font-size: 0.8vw;
            color: #6b6b6b;
            height: 25%;
            i {
              vertical-align: middle;
              display: inline-block;
              width: 6px;
              height: 6px;
              margin: 0 5%;
              background: #3651d8;
              border-radius: 50%;
            }
            span {
              display: inline-block;
              width: 80%;
            }
          }
        }
      }
      .bg2 {
        background: url("../../../../assets/image/parkImg/nh2.png") no-repeat !important;
      }
      .bg3 {
        background: url("../../../../assets/image/parkImg/nh3.png") no-repeat !important;
      }
      .bg4 {
        background: url("../../../../assets/image/parkImg/nh4.png") no-repeat !important;
      }
      .bg5 {
        background: url("../../../../assets/image/parkImg/nh5.png") no-repeat !important;
      }
      .bg6 {
        background: url("../../../../assets/image/parkImg/nh6.png") no-repeat !important;
      }
    }
  }
  .security-text {
    font-size: 1.4vw;
    color: #6b6b6b;
    margin-left: 3%;
    // transform: translateX(-50%);
    p {
      margin: 1% 0;
    }
  }
  .security-box {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    text-align: center;
    font-size: 24px;
    font-weight: 600;
    color: #333333;
    margin-top: 40px;
    .security-item {
      width: 24%;
      img {
        width: 100%;
      }
      div {
        margin: 30px 0;
      }
    }
  }
}
</style>